@import url('https://fonts.googleapis.com/css2?family=Gabarito:wght@500&family=Josefin+Sans&display=swap');

/*---------- root ----------*/

:root {
    --dark-color: #363062;
    --medium-color: #4D4C7D;
    --light-color: #F99417;
    --very-light-color: #FDE5D4;
    --grey-color: #cecdcd;
    --light-black-color: #4e4e54;
}

html {
    box-sizing: border-box;
    scroll-behavior: smooth;
}

body {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Josefin Sans', sans-serif;
    background-color: white;
    overflow-x: hidden;
}

* {
    box-sizing: border-box;
}

/* colors */

.dark-grey {
    color: var(--light-black-color);
}

/*---------- header ---------*/

#header {
    width: 100%;
    position: fixed;
    background-color: white;
    z-index: 10;
}

#header-container {
    padding: 0 125px;
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo-container {
    cursor: pointer;
}

.logo-text {
    width: 300px;
    font-size: 30px;
    color: var(--dark-color);
    letter-spacing: 1px;
    line-height: 30px;
}

#nav-bar-elements {
    list-style-type: none;
}

#nav-bar-elements li {
    font-family: 'Gabarito', sans-serif;
    display: inline;
    margin-left: 20px;
    font-size: 15px;
    cursor: pointer;
    text-transform: capitalize;
    color: var(--medium-color);
    letter-spacing: 2px;

    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

#nav-bar-elements li:hover {
    color: rgb(149, 179, 212)
}

#header hr {
    border: solid 1px var(--grey-color);
}


#mobile-navbar {
    position: fixed;
    background-color: #fef8ed;
    padding: 100px;
    margin-top: -10px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

#mobile-nav-bar-elements {
    display: flex;
    flex-direction: column;
    justify-content: center
}

#mobile-nav-bar-elements li {
    font-family: 'Gabarito', sans-serif;
    display: inline;
    margin-top: 20px;
    font-size: 15px;
    cursor: pointer;
    text-transform: capitalize;
    color: var(--medium-color);
    letter-spacing: 2px;

    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

#mobile-nav-bar-elements li:hover {
    color: rgb(149, 179, 212)
}

#burger {
    display: none;
    cursor: pointer;
}

#cross {
    cursor: pointer;
}

.logo {
    width: 250px;
}

@media all and (max-width: 1200px) {
    #nav-bar-elements {
        display: none;
    }

    #burger {
        display: block;
        position: relative;
        left: 100px;
    }

    #cross {
        display: block;
        position: relative;
        left: 100px;
    }

    #square3-container {
        display: none;
    }
}

@media all and (max-width: 750px) {
    #header-container {
        padding: 0 10px;
    }

    #nav-bar-elements {
        display: none;
    }

    .logo-text {
        width: 200px;
        font-size: 20px;
    }

    #burger {
        left: 0;
    }

    #cross {
        left: 0;
    }
}

/*---------- Body ------------*/

.body {
    position: relative;
    top: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

a,
a:hover,
a:visited,
a:active {
    color: inherit;
    text-decoration: none;
}


/* Mission */

#circle-dashed {
    position: absolute;
    z-index: -10;
    width: 100px;
    left: -70px;
    top: 30px;
}

.light-text {
    color: var(--medium-color);
}

.section {
    padding: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 170px;
}

.section>div {
    padding: 20px;
}

.subsection-horiz {
    position: relative;
    margin: 0 60px;
    width: 500px;
    display: flex;
    flex-wrap: none;
    align-items: center;
    justify-content: left;
}

#mission-diamond {
    position: absolute;
    top: 0px;
    left: -150px;
    z-index: -1;
    opacity: 50%;
}

.large-text {
    width: 400px;
    font-size: 120px;
    color: var(--dark-color);
    line-height: 100px;
}

.paragraph {
    width: 300px;
    line-height: 30px;
    color: var(--light-black-color);
}

#p-mission {
    width: 500px;
    font-size: 22px;
}

.orange-link {
    margin: 20px;
    color: var(--light-color);
    font-size: 30px;
    width: 400px;
    text-align: center;
    border-bottom: 3px solid #fd993400;
    cursor: pointer;

    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.orange-link a {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    text-decoration: none;
    line-height: 30px;
}

.arrow {
    opacity: 0;
    position: relative;
    top: 0px;
    transition: opacity 500ms;
}

.orange-link:hover .arrow {
    cursor: pointer;
    opacity: 100%;
}

#mission-title-hr {
    position: absolute;
    bottom: 70px;
}

#mission-plus {
    position: absolute;
    top: -15px;
    right: 7px;
}

#mission-plus2 {
    position: absolute;
    top: 0;
    right: 20px;
}

@media all and (max-width: 750px) {
    #circle-dashed {
        left: 100px;
        top: 40px;
        width: 40px;
    }

    .large-text {
        width: 250px;
        font-size: 40px;
    }

    #p-mission {
        width: 300px;
        font-size: 13px;
    }

    .mission-text-container {
        display: flex;
        flex-direction: column;
        width: 200px;
        justify-content: center;
        align-items: center;
    }

    .orange-link {
        font-size: 20px;
    }

    .title-hr {
        display: none;
    }

    .subsection-horiz {
        justify-content: center;
    }

    #circle-dashed {
        left: 0px;
    }
}

/* About Us */

.title-hr {
    width: 125px;
}

#about-us-title-hr {
    position: absolute;
    width: 125px;
    bottom: 20px;
}

.medium-large-text {
    font-size: 40px;
    color: var(--dark-color);
}

.medium-text {
    font-size: 25px;
    color: var(--light-black-color);
    line-height: 35px;
    letter-spacing: 1px;
}

#about-us-title {
    position: relative;
    bottom: 40px;
}

#about-us-text {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: column;
    top: 200px;
    color: var(--light-black-color);
}

#orange-line {
    display: none;
    position: absolute;
    width: 1300px;
    top: 100px;
    left: -350px;
    transform: rotate(11deg);
    -webkit-transform: rotate(11deg);
    -moz-transform: rotate(11deg);
    -ms-transform: rotate(11deg);
    -o-transform: rotate(11deg);
}

#orange-link {
    font-size: 22px;
}

.square {
    position: absolute;
    width: 500px;
    height: 250px;
}

#mpsquare {
    z-index: -100;
    top: 100px;
    left: -100px;
    background-color: #f8f8f8;
}

#abtus-shapes {
    position: absolute;
    width: 400px;
    top: -100px;
}

@media all and (max-width: 1400px) {
    .section {
        flex-direction: column;
    }

    .body {
        overflow: hidden;
    }

    #orange-line {
        display: none;
    }

    #about-us-text {
        top: 0;
    }

    #about-us {
        margin-bottom: 300px;
    }

    #abtus-shapes {
        display: none;
    }
}

#about-us {
    margin-bottom: 500px;
}

@media all and (max-width: 750px) {
    .medium-large-text {
        font-size: 20px;
        text-align: center;
    }

    #about-us-text {
        width: 300px;
    }

    .medium-text {
        font-size: 18px;
    }
}

/* Services */

#services-title {
    position: relative;
}

#services-title>h1 {
    font-size: 55px;
}

#services-title-hr {
    position: absolute;
    bottom: 15px;
    left: 5px;
}


/*.services-container {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}*/


.services-subsection {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.services {
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
}

.service {
    padding: 0 20px;
    width: 400px;
    background-color: rgba(218, 218, 218, 0.5);
    margin-bottom: 10px;
}

.service>p {
    text-align: center;
    font-size: 24px;
    color: var(--dark-color);
}

.past-project-link {
    background-color: #ffc885;
    cursor: pointer;
}

.past-project-link:hover {
    background-color: #ffa83d;
}

.past-project-link:hover>p {
    color: black;
}

.past-project-link>p {
    color: var(--light-black-color);
}

@media all and (max-width: 750px) {
    .service {
        width: 270px;
    }

    #bullseye-container {
        display: none;
    }
}

@media all and (max-width: 1400px) {
    #bullseye-container {
        display: none;
    }
}


@media all and (max-width: 300px) {
    .service {
        width: 200px;
    }
}

/* Footer */
#footer {
    background-color: #f4f4f4;
    border-top: 1.5px solid rgb(177, 177, 177);
    color: var(--light-black-color);
    margin: 0;
    max-width: 100%;
    margin-top: 0;
    padding: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    padding: 25px 0;
    position: relative;
    top: 120px;
}

@media all and (max-width: 1400px) {
    #footer {
        flex-direction: column;
    }
}

#more-info-btn {
    display: block;
    color: rgb(113, 113, 113);
    width: 100px;
}

#more-info-btn:hover {
    color: rgb(176, 176, 176);
}

#footer li {
    list-style-type: none;
    margin: 10px 0;
    letter-spacing: 2px;
    cursor: pointer;
    font-size: 20px;

    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

#footer li:hover {
    color: rgb(166, 166, 166);
}

ul {
    padding-left: 0;
}

#address {
    display: block;
    width: 300px;
}

#footer-nav {
    display: flex;
    flex-direction: row;
    justify-content: left;
    flex-wrap: none;
    width: 300px;
}

#logo-container-footer {
    width: 300px;
    margin: 0 60px;
}

/* past projects */
.past-projects-title {
    margin-top: 50px;
    margin-bottom: 10px;
    font-size: 60px;
    color: var(--dark-color)
}

.past-projects-subtitle {
    width: 800px;
    text-align: center;
    font-size: 20px;
    color: var(--light-black-color);
    margin-bottom: 50px;
}

.agriculture-img {
    position: static;
    width: 450px;
}

.project-text {
    color: var(--dark-color);
}

.square1 {
    z-index: -10;
    position: absolute;
    background-color: #f2f2f2;
    width: 500px;
    height: 200px;
    top: 100px;
    left: 100px;
}

.pp-section {
    margin-bottom: 100px;
}

.vocational-img {
    width: 500px;
}

.square2 {
    position: absolute;
    background-color: #ffeeda;
    width: 500px;
    height: 300px;
    left: 90px;
    bottom: -50px;
    z-index: -100;
}

.square3-container {
    opacity: 1;
    z-index: -100;
}

.square3 {
    position: absolute;
    z-index: -10;
    background-color: #d7dbfc;
    width: 400px;
    height: 250px;
}

.opacity {
    opacity: 50%;
}

.data-icon {
    z-index: -10;
    position: absolute;
    width: 60px;
    top: 20px;
    left: 30px;
}

.square3-plus {
    position: absolute;
    top: 260px;
    left: 300px;
}

.square3-circle {
    position: absolute;
    top: 220px;
    left: 200px;
}

.collaboration-img {
    width: 500px;
}

.improvement-img {
    width: 500px;
}

.last-pp {
    margin-bottom: 250px;
}

.mobile {
    display: none;
}

.container2 {
    width: 100vw;
}

@media all and (max-width: 1400px) {
    .comp {
        display: none;
    }

    .mobile {
        display: flex;
    }

    .square3 {
        margin-top: 150px;
    }
}

@media all and (max-width: 750px) {
    .past-projects-title {
        font-size: 28px;
    }

    .past-projects-subtitle {
        font-size: 15px;
        width: 250px;
        margin-bottom: 80px;
    }

    .pp-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 300px;
        margin-top: -100px;
    }

    .subsection-horiz {
        width: 300px;
        margin: 0;
    }

    .project-text {
        font-size: 15px;
    }

    .agriculture-img {
        width: 250px;
    }

    .vocational-img {
        width: 250px;
    }

    .action-img {
        width: 250px;
    }

    .collaboration-img {
        width: 250px;
    }

    .square2 {
        display: none;
    }

    .square3 {
        margin-bottom: 100px;
    }

    .improvement-img {
        width: 250px;
    }

    .square3 {
        width: 250px;
        height: 150px;
    }

    ;
}