.container1 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 150px;
}

.container2 {
    display: flex;
    flex-direction: column;
    width: 90%;
}

.item {
    margin-bottom: 20px;
    color: #4e4e54;
}

@media all and (max-width: 750px) {
    .container1 {
        width: 90%;
    }

    .item {
        font-size: 12px;
    }
}